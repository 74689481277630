<template>
  <div class="fill-width">
    <div v-if="!!label" class="text-body-2 mb-1">
      <span >{{ label }}</span>
    </div>

    <v-text-field
      v-model="value"
      v-on="$listeners"
      class="rounded-lg"
      background-color="accent"
      :append-icon="appendIcon"
      :placeholder="placeholder"
      :rules="required ? rules : []"
      :hide-details="hideDetails"
      :outlined="outlined"
      :readonly="readonly"
      :disabled="disabled"
      :color="color"
      :type="type"
      solo
      flat
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
    };
  },

  props: {
    currentValue: null,

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    appendIcon: {
      type: String,
      default: "",
    },

    color: {
      type: String,
      default: "primary",
    },

    type: {
      type: String,
      default: "text",
    },

    customRules: {
      type: Array,
      default: () => [],
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  beforeMount() {
    if (!!this.currentValue) this.value = this.currentValue;
  },

  computed: {
    rules() {
      return this.customRules.length <= 0
        ? [(v) => !!v || "Campo obrigatório"]
        : this.customRules;
    },
  },

  methods: {
    handleInput(event) {
      this.value = event;
      this.$emit("input", event);
    },
  },
};
</script>

<style></style>
