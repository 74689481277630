import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import api from "@/services";

Vue.use(Vuex);

const vuexLocal = new VuexPersist({
  key: "localStorage",
  storage: window.localStorage, // persistent on localstorage
  modules: ["token", "authorized", "user"], // persistent variable on state
});

export default new Vuex.Store({
  state: {
    apiURL: "https://api.earthguard.online/",
    appURL: "https://invest.earthguard.online/",
    secret: "bQmusimfgitiiXtqGNJsusimfgitXtq",
    token: null,
    user: null,
    authorized: false,
  },

  getters: {
    apiURL: ({ apiURL }) => apiURL,
    appURL: ({ appURL }) => appURL,
    secret: ({ secret }) => secret,
    authenticated: ({ token, user }) => !!token && !!user,
    token: ({ token }) => token,
    user: ({ user }) => user,
    authorized: ({ authorized }) => authorized,
  },

  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },

    SET_USER(state, payload) {
      state.user = payload;
    },

    SET_AUTHORIZED(state, payload) {
      state.authorized = payload;
    },
  },

  actions: {
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);

      // set header authotization
      api.defaults.headers.common["Authorization"] = payload;
    },

    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },

    setSignOut({ dispatch }) {
      dispatch("setToken", null);
      dispatch("setUser", null);
    },

    setAuthorized({ commit }, payload) {
      commit("SET_AUTHORIZED", payload);
    },
  },

  plugins: [vuexLocal.plugin],

  modules: {},
});
