<template>
  <v-app id="app">
    <SplashScreen v-if="loading" />

    <Application
      v-else-if="$store.getters.authenticated && $store.getters.authorized"
    >
      <router-view />
    </Application>

    <v-main v-else>
      <router-view />
    </v-main>

    <SnackBar />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { request } from "./services";
import Application from "./template/Application.vue";
import SplashScreen from "./template/SplashScreen";
import SnackBar from "./components/app/SnackBar";

export default {
  name: "App",

  data() {
    return {
      loading: true,
    };
  },

  components: {
    Application,
    SplashScreen,
    SnackBar,
  },

  created() {
    this.startSession();
  },

  methods: {
    ...mapActions(["setToken", "setUser", "setSignOut", "setAuthorized"]),

    // checks if the token is still valid for a session
    async startSession() {
      const token = this.$store.getters.token;

      if (token) {
        this.setToken(token);

        try {
          const payload = {
            method: "checkUserToken",
          };

          await request(payload).then(() => {
            this.getUser();
          });
        } catch (err) {
          this.logoff();
        }
      }

      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    async getUser() {
      try {
        const payload = {
          method: "getUserData",
        };

        await request(payload).then((res) => {
          this.setUser(res.userData);

          if (this.handleUserData(res.userData)) {
            this.setAuthorized(true);
          } else {
            this.setAuthorized(false);
            this.$router.push({ path: "/account" }).catch(() => {
              /* ignore */
            });
          }
        });
      } catch (err) {
        // err
      }
    },

    handleUserData(data) {
      return (
        Object.keys(data.dadosPessoais).length > 0 &&
        Object.keys(data.dadosResidencial).length > 0
      );
    },

    logoff() {
      this.setSignOut();
      this.$router.push({ path: "/auth" }).catch(() => {
        /* ignore */
      });
    },
  },
};
</script>

<style src="./styles/style.scss" lang="scss" />
