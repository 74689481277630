<template>
  <div class="d-flex flex-column align-center justify-center">
    <v-progress-circular indeterminate size="48" color="primary" />
  </div>
</template>

<script>
export default {};
</script>

<style></style>