<template>
  <div class="d-flex flex-column fill-height">
    <v-app-bar
      v-if="!_hideTemplate"
      class="pl-2"
      color="foreground"
      style="border-bottom: 1px solid #d2d2d2 !important"
      flat
      app
    >
      <v-btn class="mr-4" small icon @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <div
        v-if="$vuetify.breakpoint.mdAndUp"
        class="text-h6 font-weight-regular"
      >
        <span>{{ _title }}</span>
      </div>

      <v-spacer />

      <div class="d-flex align-center">
        <router-link to="/profile" class="text-decoration-none">
          <v-card
            class="d-flex py-1 px-2 align-center mr-4 rounded-lg"
            outlined
          >
            <v-avatar size="28" class="mr-2" color="surface">
              <v-img :src="_profilePicture" />
            </v-avatar>

            <div class="text-body-1 overtext--text">
              <span>{{ user.name }}</span>
            </div>
          </v-card>
        </router-link>

        <v-btn small icon @click="logoff()">
          <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-if="!_hideTemplate"
      v-model="drawer"
      color="foreground"
      app
    >
      <div class="d-flex fill-height">
        <vue-custom-scrollbar
          class="scroll-area d-flex flex-column py-6"
          :settings="scrollSettings"
        >
          <!-- logo -->
          <div>
            <v-row class="mb-4 ml-2">
              <v-col cols="7">
                <v-img :src="require('@/assets/logotype.svg')" contain />
              </v-col>
            </v-row>
          </div>

          <div class="d-flex flex-column px-4">
            <!-- routes -->
            <v-list class="transparent pa-0 mb-6" dense nav>
              <router-link
                v-for="(item, i) in routes"
                v-show="!item.hided"
                v-slot="{ href, navigate, isActive }"
                :to="item.path"
                :key="i"
                custom
              >
                <v-list-item
                  :href="href"
                  :disabled="item.disabled"
                  :class="`${!item.disabled || 'disabled-item'} ${
                    !isActive || 'surface'
                  } mb-2`"
                  @click="navigate"
                >
                  <v-list-item-icon v-if="item.icon" class="mr-2">
                    <v-icon :color="isActive ? 'primary' : 'overtext'">{{
                      isActive ? item.icon : item.icon + "-outline"
                    }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title
                    class="text-body-2"
                    :class="
                      isActive
                        ? 'primary--text font-weight-bold'
                        : 'overtext--text'
                    "
                  >
                    <span>
                      {{ item.label }}
                    </span>

                    <v-chip
                      v-if="item.pro"
                      class="ml-2 black--text"
                      color="secondary"
                      x-small
                    >
                      PRO
                    </v-chip>
                  </v-list-item-title>
                </v-list-item>
              </router-link>
            </v-list>
          </div>
        </vue-custom-scrollbar>
      </div>
    </v-navigation-drawer>

    <!-- slot view -->
    <v-main class="background">
      <slot />
    </v-main>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { request } from "@/services";
import vueCustomScrollbar from "vue-custom-scrollbar/src/vue-scrollbar.vue";

export default {
  data() {
    return {
      drawer: true,
      scrollSettings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
    };
  },

  components: {
    vueCustomScrollbar,
  },

  computed: {
    ...mapState(["user", "authorized"]),

    _profilePicture() {
      if (this.user.profilePhoto !== "assets/pics/profilephoto.jpg")
        return this.$store.getters.apiURL + this.user.profilePhoto;
      else return require("@/assets/utils/profile.png");
    },

    _hideTemplate() {
      return !!this.$route.meta.hideTemplate;
    },

    _title() {
      switch (this.$route.name) {
        case "Início":
          return "Boas vindas, " + this.user.name;
        case "Minhas UCS":
          return "Suas UCS";
        case "Detalhes da UCS":
          return "Informações da sua UCS #" + this.$route.params.id;
        case "UCS":
          return "UCS disponíveis";
        case "Detalhes da UCS":
          return "Detalhes da multi UCS";
        case "Trnasações":
          return "Transações gerais";
        case "Comissões":
          return "Suas comissões";
        case "Perfil":
          return "Detalhamento do seu perfil";
        default:
          break;
      }
    },

    routes() {
      return [
        {
          label: "Início",
          path: "/home",
          icon: "mdi-view-dashboard",
          disabled: !this.authorized,
        },
        {
          label: "UCS",
          path: "/investment",
          icon: "mdi-lightbulb",
          disabled: !this.authorized,
        },
        {
          label: "Minhas UCS",
          path: "/my-properties",
          icon: "mdi-home-variant",
          disabled: !this.authorized,
        },
        {
          label: "Transações",
          path: "/transactions",
          icon: "mdi-alpha-s-circle",
          disabled: !this.authorized,
        },
        {
          label: "Minhas comissões",
          path: "/commissions",
          icon: "mdi-account-group",
          disabled: !this.authorized,
          hided: true, //this.user.ativaComissao === "0",
        },
        {
          label: "Meu perfil",
          path: "/profile",
          icon: "mdi-account",
          disabled: !this.authorized,
        },
        /* {
          label: "FAQ",
          path: "/faq",
          icon: "mdi-forum",
        }, */
      ];
    },
  },

  beforeMount() {
    this.$root.$on("refresh-user", () => {
      this.getUser();
    });
  },

  beforeDestroy() {
    this.$root.$off("refresh-user");
  },

  methods: {
    ...mapActions(["setUser", "setSignOut"]),

    async getUser() {
      try {
        const payload = {
          method: "getUserData",
        };

        await request(payload).then((res) => {
          this.setUser(res.userData);
        });
      } catch (err) {
        console.log(err);
      }
    },

    logoff() {
      this.setSignOut();
      this.$router.push({ path: "/auth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-area {
  height: 100%;
  width: 100%;
}

.disabled-item {
  opacity: 0.5;
}
</style>
