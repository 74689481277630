import store from "../store";

export default async (to, from, next) => {
  //settings
  document.title = to.name + " - Earth guard";
  if (to.name != from.name) window.scrollTo(0, 0);

  const tk = to.query.tk || false;
  if (!!tk) store.dispatch("setToken", tk);

  // handle auth state
  const requiredAuth = to.meta.requiredAuth || false;
  const preventAuth = to.meta.preventAuth || false;
  const requiredKyc = to.meta.requiredKyc || false;
  const authenticated = store.getters.authenticated ? true : false;

  // if the user has not authenticated and tries access private page
  if (requiredAuth && !authenticated) return next("/auth");

  // if the user has authenticated and tries access prevent authenticated page
  if (preventAuth && authenticated) return next("/");

  // kyc validation
  if (authenticated && requiredKyc && !store.getters.authorized)
    return next("/account");

  // else... next
  next();
};
