import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/auth",
    component: () => import("../views/auth/Auth.vue"),
    children: [
      {
        path: "",
        name: "Acessar",
        meta: { preventAuth: true },
        component: () => import("../views/auth/children/SignIn.vue"),
      },
      {
        path: "sign-up",
        name: "Cadastrar",
        meta: { preventAuth: true },
        component: () => import("../views/auth/children/SignUp.vue"),
      },
      {
        path: "forgot-password",
        name: "Esqueci a senha",
        meta: { preventAuth: true },
        component: () => import("../views/auth/children/ForgotPassword.vue"),
      },
    ],
  },
  {
    path: "/home",
    name: "Início",
    meta: { requiredAuth: true, requiredKyc: true },
    component: () => import("../views/home/Home.vue"),
  },
  {
    path: "/my-properties",
    meta: { requiredAuth: true, requiredKyc: true },
    component: () => import("../views/myProperties/MyProperties.vue"),
    children: [
      {
        path: "",
        name: "Minhas UCS",
        meta: { requiredAuth: true, requiredKyc: true },
        component: () =>
          import("../views/myProperties/children/MyProperties.vue"),
      },
      {
        path: ":id",
        name: "Detalhes da UCS",
        meta: { requiredAuth: true, requiredKyc: true },
        component: () => import("../views/myProperties/children/Details.vue"),
      },
      {
        path: ":id/see-contract",
        name: "Ver contrato",
        meta: { requiredAuth: true, requiredKyc: true, hideTemplate: true },
        component: () =>
          import("../views/myProperties/children/SeeContract.vue"),
      },
    ],
  },
  {
    path: "/investment",
    meta: { requiredAuth: true, requiredKyc: true },
    component: () => import("../views/investment/Investment.vue"),
    children: [
      {
        path: "",
        name: "UCS",
        meta: { requiredAuth: true, requiredKyc: true },
        component: () => import("../views/investment/children/Investment.vue"),
      },
      {
        path: ":id",
        name: "Detalhes da UCS",
        meta: { requiredAuth: true, requiredKyc: true },
        component: () => import("../views/investment/children/Details.vue"),
      },
    ],
  },
  {
    path: "/account",
    name: "Conta",
    meta: { requiredAuth: true },
    component: () => import("../views/account/Account.vue"),
  },
  {
    path: "/transactions",
    name: "Trnasações",
    meta: { requiredAuth: true, requiredKyc: true },
    component: () => import("../views/transactions/Transactions.vue"),
  },
  /*   {
    path: "/commissions",
    name: "Comissões",
    meta: { requiredAuth: true, requiredKyc: true },
    component: () => import("../views/commissions/Commissions.vue"),
  }, */
  {
    path: "/profile",
    name: "Perfil",
    meta: { requiredAuth: true, requiredKyc: true },
    component: () => import("../views/profile/Profile.vue"),
  },
  /*  {
    path: "/faq",
    name: "FAQ",
    meta: { requiredAuth: true, requiredKyc: true },
    component: () => import("../views/faq/Faq.vue"),
  }, */
  {
    path: "*",
    redirect: "/home",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
