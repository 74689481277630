import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },

    themes: {
      light: {
        primary: "#48AB6F",
        primary2: "#9ed36d",
        secondary: "#100A55",
        info: "#2196F3",
        success: "#4CAF50",
        error: "#FF5252",
        warning: "#FFC107",
        background: "#F7F7FD",
        foreground: "#FFFFFF",
        accent: "#E8E6F9",
        surface: "#F7F7FD",
        overtext: "#3F3F44",
        subtext: "#8A8A8E",
      },
    },
  },

  lang: {
    locales: { pt },
    current: "pt",
  },
});
