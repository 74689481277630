<template>
  <v-container class="background" fill-height align-center justify-center fluid>
    <v-progress-circular indeterminate size="64" color="primary" />
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>